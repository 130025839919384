import React from 'react'
import Link from '../components/Link'
import PaddingPaper from '../components/styledComponents/PaddingPaper'
import TopContainer from '../components/styledComponents/TopContainer'
import TitleBox from '../components/styledComponents/TitleBox'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const Careers = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <PaddingPaper>
          <TitleBox>
            <Typography
              align='center'
              variant='h4'
              component='h2'
              color='secondary'
            >
              Help us revolutionize financial analysis
            </Typography>
          </TitleBox>
          <List>
            <ListItem>
              <ListItemText
                primary='Sales Representative'
                secondary={
                  <>
                    <Typography variant='body2' component='p'>
                      We are looking for someone with strong communication
                      skills, as well as the ability to understand
                      functionalities of highly technical software, at least
                      from a user perspective.
                    </Typography>
                    <Typography variant='body2' component='p'>
                      Preference for someone with experience in sales
                      departments of software/data firms providing services to
                      financial markets.
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='Software Engineering Intern'
                secondary={
                  <>
                    <Typography variant='body2' component='p'>
                      You will learn a wide array of skills, from updating a
                      top-level dynamic data infrastructure to building new
                      innovative machine learning models.
                    </Typography>
                    <Typography variant='body2' component='p'>
                      Required skills: strong programming proficiency in Python.
                    </Typography>
                    <Typography variant='body2' component='p'>
                      The following are a plus: PyTorch, Cython, Numba, WebGL.
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
          <Typography>
            To apply please email your resume to:{' '}
            <Link
              href='mailto:contact@gematria.tech'
              underline='none'
              color='secondary'
            >
              contact@gematria.tech
            </Link>
          </Typography>
        </PaddingPaper>
      </TopContainer>
    </Box>
  )
}

export default Careers
